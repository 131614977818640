<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <!-- <v-label
      ><b>{{ label }}</b></v-label
    > -->
    <s-select 
        :items="items"
        item-value="PrdID"
        full
        autocomplete
        item-text="PrdCardName"
        label="Productor"
        @input="input($event)"
        :slotTmp="true"
        @change="changeProducer($event)"
    >
        <template v-slot:item="data">
            {{ data.item.PrdCardCode + ' - ' + data.item.PrdCardName }}
             
        </template>
    </s-select>
  </div>
</template>

<script>
import _sService from "@/services/Technicalassistance/HarvestSchedule.js";
export default {
    components: {},
    props: {
        disabled: {
        type: Boolean,
        default: false,
        },
        clearable: {
        type: Boolean,
        default: false,
        },
        value: null,
        text: { type: String, default: "" },
        label: {
        type: String,
        default: "",
        },
        returnObject: {
        type: Boolean,
        default: false,
        },
        autofocus: {
        type: Boolean,
        default: false,
        },
        produceID:{
        type: Number,
        default: 0,
        },
        noDefault: {
        type: Boolean,
        default: false,
        },    
        full: {
        type: Boolean,
        default: false,
        },
        FagID: {
        type: Number,
        default: 0
        },
        onClear:{
                    type: Boolean,
                    default: false,
                },
    },
    watch: {
        text(){
        },
        value(value) {
        this.item = value;
        },
        FagID(){
        if(this.FagID != 0){
            console.log('cambio de fag ', this.FagID);
            this.config.params.FagID=this.FagID
        }
            
        }
    },
    methods: {
        changeProducer(data){ 
            if (data > 0) { 
                var prod = this.items.filter(x => x.PrdID == data); 
                this.$emit('changeProd', prod[0])
            } 
        },
        onClearClicked(){
        this.$emit("onClearClicked");     
        },
        input(val) {
        this.$emit("input", val);
        },
    },
    data: () => ({
        item: 0,
        val: "",
        items: [], 
    }),
    computed: {
        formattedItems() {
            return this.items.map(item => ({
            ...item,
            formattedText: `${item.PrdCardCode} - ${item.PrdCardName}`
            }));
        }
    },

    created() {
        
        _sService.listProducer(this.FagID, "", this.$fun.getUserID())
            .then((r) => {
                this.items = r.data
            })
    
  },
};
</script>
